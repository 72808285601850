<template>
    <ul class="misc-layout-chips">
        <li v-for="(category, index) in categories" :key="index">
            <NuxtLink
                no-prefetch
                :to="`/${$lang.routes.categories}/${category.slug}`"
                class="misc-layout-chips__link"
            >
                <picture v-if="category.image" class="misc-layout-chips__icon">
                    <img
                        :src="category.image.url"
                        :alt="category.name"
                        :title="category.name"
                        width="22"
                        height="22"
                        loading="lazy"
                    />
                </picture>
                <p>{{ category.name }}</p>
            </NuxtLink>
        </li>
    </ul>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'MicroCategoriesChips',
    props: {
        categories: {
            type: Array as PropType<Models.Category[]>,
            required: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.misc-layout-chips {
    @apply flex flex-wrap;
    li {
        @apply block;
    }
    &__link {
        @apply m-1.5 mb-3 mt-0 flex items-center space-x-2 rounded-lg bg-white px-5 py-2 text-sm;
    }
}
</style>
