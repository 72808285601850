<template>
    <article class="slider-card">
        <NuxtLink
            v-if="(info.is_admin || info.without_detail) && info.store?.slug"
            class="slider-card__image"
            :to="`/${$lang.routes.brands}/${info.store?.slug}`"
        >
            <picture class="slider-card__image-first">
                <img
                    :src="info.featured_image || defaultImage"
                    alt="product image"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                    title="product image"
                />
            </picture>
            <picture class="slider-card__image-second">
                <img
                    v-if="info.store && info.store.image"
                    :src="info.store.image.url || defaultImage"
                    alt="product image"
                    :onerror="`this.onerror=null;this.src='${defaultStoreImage}'`"
                    title="product image"
                />
            </picture>
        </NuxtLink>
        <NuxtLink
            no-prefetch
            v-else
            class="slider-card__image"
            :to="{
                name: isOffer ? $lang.routes.offers + '-slug' : $lang.routes.coupons + '-slug',
                params: { slug: info.slug },
            }"
        >
            <picture class="slider-card__image-first">
                <img
                    :src="info.featured_image || defaultImage"
                    alt="product image"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                    title="product image"
                />
            </picture>
            <picture class="slider-card__image-second">
                <img
                    v-if="info.store && info.store.image"
                    :src="info.store.image.url || defaultImage"
                    alt="product image"
                    :onerror="`this.onerror=null;this.src='${defaultStoreImage}'`"
                    title="product image"
                />
            </picture>
        </NuxtLink>
        <section v-if="info.is_admin || info.without_detail" class="slider-card__info">
            <NuxtLink
                v-if="(info.is_admin || info.without_detail) && info.store?.slug"
                :to="`/${$lang.routes.brands}/${info.store?.slug}`"
                class="info-title"
            >
                {{ info.title }}
            </NuxtLink>

            <NuxtLink
                v-if="(info.is_admin || info.without_detail) && info.store?.slug"
                :to="`/${$lang.routes.brands}/${info.store?.slug}`"
                class="info-description"
            >
                Ver Descuento
                <img
                    class="info-description__icon"
                    :src="$assets.primary.arrowRightCategory"
                    alt="arrow right"
                />
            </NuxtLink>
        </section>

        <section v-else class="slider-card__info">
            <NuxtLink
                :to="{
                    name: isOffer ? $lang.routes.offers + '-slug' : $lang.routes.coupons + '-slug',
                    params: { slug: info.slug },
                }"
                class="info-title"
            >
                {{ info.title }}
            </NuxtLink>

            <NuxtLink
                :to="{
                    name: isOffer ? $lang.routes.offers + '-slug' : $lang.routes.coupons + '-slug',
                    params: { slug: info.slug },
                }"
                class="info-description"
            >
                Ver Descuento
                <img
                    class="info-description__icon"
                    :src="$assets.primary.arrowRightCategory"
                    alt="arrow right"
                />
            </NuxtLink>
        </section>
    </article>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Models } from '~/types/models'

const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
    lazyImage: Boolean,
    expire: {
        type: Boolean,
        default: false,
    },
    noAuth: {
        type: Boolean,
        default: false,
    },
})

const { defaultImages } = useVariantsDictionary()

const Route = useRoute()

const { $lang, $openers } = useNuxtApp()

const defaultImage = defaultImages[props.info.type]
const defaultStoreImage = defaultImages.store

const isCoupon = props.info.type === 'coupon'
const isOffer = props.info.type === 'offer'

const inStoreRoute = computed(() => {
    return Route.path.includes(`/${$lang.routes.brands}/`)
})

const openCoupon = () => {
    if (props.info.is_admin || props.info.without_detail) {
        $openers.openCoupon(props.info.slug, props.info.without_detail ? '' : props.info.store?.slug || '')
    } else {
        $openers.openCoupon(props.info.slug)
    }
}
const openOffer = () => {
    $openers.openOffer(props.info.id)
}
</script>

<style scoped lang="postcss">
.slider-card {
    @apply flex h-[140px] rounded-[4px] bg-white shadow-sm xs:h-[120px] md:h-[180px] lg:h-[220px];

    &__image {
        @apply relative h-[140px] w-[120px] flex-shrink-0 overflow-hidden rounded-l-[4px] xs:h-[120px] md:h-[180px] md:w-[140px] lg:h-[220px];

        &-first {
            @apply block h-full w-full object-cover;
            img {
                @apply h-full w-full object-cover;
            }
        }

        &-second {
            @apply absolute bottom-5 left-1/2 block h-11 w-11 -translate-x-1/2 overflow-hidden rounded-full border border-gray-300 border-opacity-50 bg-white object-cover md:h-16 md:w-16 lg:transition-transform lg:duration-300 lg:ease-in-out;
            img {
                @apply block h-full w-full object-contain p-2;
            }
        }
    }

    &__info {
        @apply flex w-full flex-col p-2 md:p-5;

        & .info-title {
            @apply line-clamp-4 break-words text-left text-base font-semibold xs:line-clamp-3 md:line-clamp-4 md:text-xl lg:line-clamp-5;
        }

        & .info-description {
            @apply mt-auto flex items-center gap-x-[6px] text-left text-xs font-semibold text-site-primary;

            &__icon {
                @apply h-5 w-5 lg:transition-transform lg:duration-300 lg:ease-in-out;
            }
        }
    }

    &:hover .slider-card__image-second {
        @apply lg:scale-110;
    }
    &:hover .info-description__icon {
        @apply lg:translate-x-2;
    }
}
</style>
