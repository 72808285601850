<script lang="ts" setup>
import type { PropType } from 'vue'

import type { Models } from '~/types/models'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { defaultImages } = useVariantsDictionary()

const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
    lazyImage: Boolean,
    expire: {
        type: Boolean,
        default: false,
    },
    variant: {
        type: String as PropType<'horizontal' | 'vertical'>,
        default: 'horizontal',
    },
    noAuth: {
        type: Boolean,
        default: false,
    },
})

const Route = useRoute()

const { $lang, $openers } = useNuxtApp()

const defaultImage = defaultImages.store

const isCoupon = props.info.type === 'coupon'

const isOffer = props.info.type === 'offer'

const inStoreRoute = computed(() => {
    return Route.path.includes(`/${$lang.routes.brands}/`)
})

const openCoupon = (forceStoreAperture = false) => {
    if (props.info.is_admin || props.info.without_detail || (forceStoreAperture && props.info.store?.slug)) {
        $openers.openCoupon(props.info.slug, props.info.without_detail ? '' : props.info.store?.slug || '')
    } else {
        $openers.openCoupon(props.info.slug)
    }
}
const openOffer = () => {
    $openers.openOffer(props.info.id)
}
</script>

<template>
    <article class="multi-discount-card" :class="variant">
        <picture class="multi-discount-card__logo">
            <img
                class="multi-discount-card__logo-image"
                :class="variant"
                :src="info.store?.image?.url || defaultImage"
                :alt="`Tienda ${info.store?.name}`"
                :title="`Tienda ${info.store?.name}`"
                width="150px"
                :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                height="150px"
            />
        </picture>

        <section class="multi-discount-card__info" :class="variant">
            <div class="multi-discount-card__info-top">
                <div class="multi-discount-card__tags">
                    <MiscTag
                        v-if="info.store"
                        :label="`Cupones ${info.store?.name}`"
                        variant="link"
                        :url="`/${$lang.routes.brands}/${info.store?.slug}`"
                    />
                </div>

                <NuxtLink
                    no-prefetch
                    v-if="isOffer && !info.without_detail"
                    class="multi-discount-card__info-title"
                    :to="{
                        name: $lang.routes.offers + '-slug',
                        params: { slug: info.slug },
                    }"
                    :class="{
                        'line-clamp-4': info.store && (info.is_exclusive || info.is_verified),
                        'line-clamp-5': info.store && !info.is_exclusive && !info.is_verified,
                        'line-clamp-7': !info.store && !info.is_exclusive && !info.is_verified,
                    }"
                >
                    {{ info.title }}
                </NuxtLink>
                <button
                    v-else
                    type="button"
                    class="multi-discount-card__info-title"
                    @click=";(isCoupon && openCoupon(true)) || (isOffer && openOffer())"
                    :class="{
                        'line-clamp-4': info.store && (info.is_exclusive || info.is_verified),
                        'line-clamp-5': info.store && !info.is_exclusive && !info.is_verified,
                        'line-clamp-7': !info.store && !info.is_exclusive && !info.is_verified,
                    }"
                >
                    {{ info.title }}
                </button>
            </div>

            <div
                v-if="!!info.is_exclusive || !!info.is_verified"
                class="multi-discount-card__tags multi-discount-card__tags--bottom"
            >
                <MiscTag
                    label="Exclusivo"
                    variant="exclusive"
                    :is-small="!!info.is_verified"
                    v-if="!!info.is_exclusive"
                />
                <MiscTag
                    label="Verificado"
                    variant="verified"
                    :is-small="!!info.is_exclusive"
                    v-if="!!info.is_verified"
                />
            </div>
        </section>
    </article>
</template>

<style scoped lang="postcss">
.multi-discount-card {
    @apply flex h-[150px] w-[300px] rounded-[4px] bg-white shadow-sm md:h-[162px] md:w-[316px];

    &.vertical {
        @apply h-[344px] w-[305px] flex-col md:w-[316px];
    }

    &__logo {
        @apply flex h-[150px] w-[150px] flex-shrink-0 rounded-l-[4px] md:h-[162px] md:w-[148px];

        &.vertical {
            @apply h-[202px] w-[305px] rounded-l-none rounded-t-[4px] md:w-[316px];
        }

        &-image {
            @apply block h-full w-full rounded-l-[4px] object-contain p-1;

            &.vertical {
                @apply rounded-l-none rounded-t-[4px];
            }
        }
    }

    &__info {
        @apply flex h-[150px] flex-1 flex-col gap-y-1 p-3 md:h-[162px];

        &.vertical {
            @apply p-4;
        }

        &-top {
            @apply flex flex-col gap-y-[2px] md:gap-y-1;
        }

        &-title {
            @apply self-center text-ellipsis text-left text-base font-semibold leading-tight tracking-tighter text-black lg:hover:underline;

            &.vertical {
                @apply h-[40px];
            }
        }
    }

    &__tags {
        &--bottom {
            @apply mt-auto flex space-x-2;
        }
    }
}
</style>
